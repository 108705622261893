import { useEffect } from "react";
import Categories from "../../components/Categories/Categories";
import Contact from "../../components/Contact/Contact";
import FeaturedProducts from "../../components/FeaturedProducts/FeaturedProducts";
import Slider from "../../components/Slider/Slider";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { resetCart } from "../../redux/cartReducer";
import { useLocation } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSuccess = queryParams.get("success") === "true";
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Payment Successful!", {
        position: "top-center",
        autoClose: 3000, // Auto-close the message after 3000ms (3 seconds)
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch(resetCart());
    }
  }, [isSuccess]);

  // Render the success page content...

  return (
    <div className="home">
      <Slider />
      <Categories />

      <FeaturedProducts type="featured" />
      <FeaturedProducts type="trending" />
      <Contact />
    </div>
  );
};

export default Home;
