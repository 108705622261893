import { useParams } from "react-router-dom";
import List from "../../components/List/List";
import style from "./Products.module.scss";
import { ChangeEvent, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";

const Products = () => {
  const params = useParams();
  const catId: number | undefined = params.id ? parseInt(params.id) : undefined;
  const [maxPrice, setMaxPrice] = useState<number>(5000);
  const [sort, setSort] = useState<string>("asc");
  const [selectedSubCats, setSelectedSubCats] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Reset filters when catId changes
  useEffect(() => {
    setSelectedSubCats([]);
    setMaxPrice(5000);
    setSort("asc");
  }, []);

  const { data, loading, error } = useFetch(
    `/sub-categories?[filters][categories][id][$eq]=${catId}`
  );
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedSubCats([...selectedSubCats, value]);
    } else {
      setSelectedSubCats(
        selectedSubCats.filter((item: string) => item !== value)
      );
    }
  };

  return (
    <div className={style.products}>
      <div className={style.left}>
        <div className={style.filterItem}>
          <h2>Product Categories</h2>
          {data?.data?.map((item: any) => {
            return (
              <div className={style.inputItem} key={item.id}>
                <input
                  type="checkbox"
                  id={item.id.toString()}
                  value={item.id}
                  onChange={handleChange}
                />
                <label htmlFor={item.id.toString()}>
                  {item.attributes.title}
                </label>
              </div>
            );
          })}
        </div>
        <div className={style.filterItem}>
          <h2> Filter by price</h2>
          <div className={style.inputItem}>
            <span>0</span>
            <input
              type="range"
              min={0}
              max={5000}
              value={maxPrice}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setMaxPrice(value);
              }}
            />
            <span>{maxPrice}</span>
          </div>
        </div>
        <div className={style.filterItem}>
          <h2> Sort by</h2>
          <div className={style.inputItem}>
            <input
              type="radio"
              id="asc"
              value="asc"
              name="price"
              onChange={(e) => {
                setSort("asc");
              }}
            />
            <label htmlFor="asc">Price (Lowest firts)</label>
          </div>
          <div className={style.inputItem}>
            <input
              type="radio"
              id="desc"
              value="desc"
              name="price"
              onChange={(e) => {
                setSort("desc");
              }}
            />
            <label htmlFor="desc">Price (Highest first)</label>
          </div>
        </div>
        <div className={style.filterItem}></div>
      </div>
      <div className={style.right}>
        <div className={style.heading}>
          <h1>
            {catId === 3 && "Men"}
            {catId === 4 && " WOMEN"}
          </h1>
        </div>

        {/* <img
          className={style.catImg}
          src="https://images.pexels.com/photos/934070/pexels-photo-934070.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          alt=""
        /> */}
        <List
          catId={catId}
          maxPrice={maxPrice}
          sort={sort}
          subCats={selectedSubCats}
          setSelectedSubCats={setSelectedSubCats}
        />
      </div>
    </div>
  );
};

export default Products;
