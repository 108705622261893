// Loader.tsx

import React from "react";
import style from "./Loader.module.scss";

const Loader: React.FC = () => {
  return (
    <div className={style.loader}>
      <div className={style.spinner}></div>
    </div>
  );
};

export default Loader;
