import React, { useEffect, useState } from "react";
// import Card from "../Card/Card";
import style from "./FeaturedProducts.module.scss";
import Card from "../Card/Card";
import axios from "axios";
import { Interface } from "readline";
import useFetch from "../../hooks/useFetch";
import Loader from "../Loader/Loader";
// import useFetch from "../../hooks/useFetch";

interface FeaturedProductsInterface {
  type: string;
}

const FeaturedProducts = ({ type }: FeaturedProductsInterface) => {
  const { data, loading, error } = useFetch(
    `/products?populate=*&[filters][type][$eq]=${type}`
  );

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  // console.log(data);

  return (
    <div className={style.featuredProducts}>
      <div className={style.top}>
        <h1> {type} products</h1>
        {type === "featured" && (
          <p>
            Discover our handpicked selection of Featured Products that are the
            epitome of style and quality. From trendy pieces to timeless
            classics, these items are carefully curated to elevate your fashion
            game. Whether you're preparing for a special occasion or upgrading
            your everyday wardrobe, our Featured Products are sure to make you
            stand out.
          </p>
        )}
        {type === "trending" && (
          <p>
            Stay ahead of the fashion curve with our Trending Products,
            meticulously chosen to reflect the latest styles and trends. From
            chic outfits to must-have accessories, these items are designed to
            keep you looking effortlessly stylish. Embrace the latest fashion
            waves and let our Trending Products inspire your unique style
            journey.
          </p>
        )}
      </div>
      <div className={style.bottom}>
        {/* {data?.map((item) => (
          <Card item={item} key={item?.id} />
        ))} */}

        {error
          ? "Something went wrong!"
          : loading
          ? "loading"
          : data?.data.map((item: any) => <Card item={item} key={item.id} />)}
      </div>
    </div>
  );
};

export default FeaturedProducts;
