// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { makeRequest } from "../makeRequest";

// type Product = {
//   id: number;
//   attributes: {
//     createdAt: string;
//     desc: string;
//     isNew: true;
//     price: number;
//     publishedAt: string;
//     title: string;
//     type: string;
//     oldPrice: string;
//     img: {
//       data: {
//         attributes: {
//           url: string;
//         };
//       };
//     };
//     img2: {
//       data: {
//         attributes: {
//           url: string;
//         };
//       };
//     };
//   };
// };

// type GetProductResponse = {
//   data: Product[]; // Nested data property
//   meta: {
//     pagination: {
//       page: number;
//       pageSize: number;
//       pageCount: number;
//       total: number;
//     };
//   };
// };

// interface FetchPromise {
//   data: Product[];
//   loading: boolean;
//   error: string | null;
// }

// const useFetch = (url: string): FetchPromise => {
//   const [data, setData] = useState<Product[]>([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchData = async (): Promise<void> => {
//       try {
//         const apiUrl = process.env.REACT_APP_API_URL;

//         if (!apiUrl) {
//           throw new Error("API URL is not defined");
//         }

//         const response = await makeRequest.get<GetProductResponse>(url);

//         console.log(response.data.data);
//         setData(response.data.data);
//         setLoading(false);
//       } catch (error) {
//         setError("Error fetching data");
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [url]);

//   return { data, loading, error };
// };

// export default useFetch;

import { useEffect, useState } from "react";
import { makeRequest } from "../makeRequest";
import debounce from "lodash.debounce";

interface ApiResponse<T> {
  data: T | null;
  loading: boolean; // Add a loading state to track API call status
  error: string | null;
}

const useFetch = (url: string): ApiResponse<any> => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(false); // Set initial loading state to false
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      console.log(apiUrl, url);

      if (!apiUrl) {
        throw new Error("API URL is not defined");
      }

      setLoading(true); // Set loading to true before making the API call

      const response = await makeRequest.get<any>(url);

      setData(response.data);
      setLoading(false); // Set loading to false after the API call is complete
    } catch (error) {
      setError("Error fetching data");
      setLoading(false); // Set loading to false even if there's an error
    }
  };
  const debouncedFetchData = debounce(fetchData, 300); //

  useEffect(() => {
    // Fetch data when the component mounts and whenever the URL changes
    debouncedFetchData();
    // fetchData();

    // Clean up the fetch function when the component unmounts
    return () => {
      // If you need to cancel any pending fetch requests, handle it here
    };
  }, [url]); // Only run the effect when the URL changes

  return { data, loading, error };
};

export default useFetch;
