import React from "react";
import style from "./Categories.module.scss";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <>
      <h2 className={style.heading}>
        Discover Fashion for All <br /> Elevate Your Style with Trendsetting
        Collections
      </h2>

      <div className={style.categories}>
        <div className={style.col}>
          <div className={style.row}>
            <img src="/img/banner8.jpg" alt="" />
            <Link className="link" to="/products/4">
              <button>SHOP </button>
            </Link>
          </div>
          <div className={style.row}>
            <img src="/img/banner9.jpg" alt="" />
            <button>
              <Link className="link" to="/products/4">
                SHOP
              </Link>
            </button>
          </div>
        </div>
        <div className={style.col}>
          <div className={style.row}>
            <img src="/img/banner3.jpg" alt="" />
            <Link className="link" to="/products/3">
              <button>SHOP </button>
            </Link>
          </div>
        </div>
        <div className={`${style.col} ${style.colLarge}`}>
          <div className={style.row}>
            <div className={style.col}>
              <div className={style.row}>
                <img src="/img/banner6.jpg" alt="" />
                <button>
                  <Link className="link" to="/products/4">
                    SHOP
                  </Link>
                </button>
              </div>
            </div>
            <div className={style.col}>
              <div className={style.row}>
                <img src="/img/banner7.jpg" alt="" />
                <button>
                  <Link className="link" to="/products/3">
                    SHOP
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className={style.row}>
            <img src="/img/banner5.jpg" alt="" />
            <button>
              <Link className="link" to="/products/4">
                SHOP
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
