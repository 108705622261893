import React from "react";
import style from "./Card.module.scss";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component"; // Import LazyLoadImage

type ProductAttributes = {
  createdAt: string;
  desc: string;
  isNew: true;
  price: number;
  publishedAt: string;
  title: string;
  type: string;
  oldPrice: string;
  img: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
  img2: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
};

interface CardProps {
  item: {
    attributes: ProductAttributes;
    id: number;
  };
}

const Card = ({ item }: CardProps) => {
  // console.log(item, "item");
  return (
    <Link className={`${style.link}  link `} to={`/product/${item?.id}`}>
      <div className={style.card}>
        <div className={style.image}>
          {item?.attributes.isNew && (
            <span className={style.new}>New Season</span>
          )}

          {/* <img
            src={
              // process.env.REACT_APP_UPLOAD_URL +
              item.attributes?.img?.data?.attributes.url
            }
            alt="first img"
            className={style.mainImg}
          />
          <img
            src={
              // process.env.REACT_APP_UPLOAD_URL +
              item.attributes?.img2?.data?.attributes?.url
            }
            alt="second img"
            className={style.secondImg}
          /> */}
          {/* Use LazyLoadImage instead of img */}
          <LazyLoadImage
            src={item.attributes?.img?.data?.attributes.url}
            alt="first img"
            effect="opacity" // Optional effect to apply when the image loads
            className={`${style.mainImg} ${style.img}`}
          />
          <LazyLoadImage
            src={item.attributes?.img2?.data?.attributes?.url}
            alt="second img"
            effect="opacity" // Optional effect to apply when the image loads
            className={`${style.secondImg} ${style.img}`}
          />

          {/* <img src={item.attributes?.} alt="" className={style.secondImg} /> */}
        </div>
        <h2>{item?.attributes?.title}</h2>
        <div className={style.prices}>
          <h3>₹ {item.attributes?.oldPrice || item?.attributes?.price + 20}</h3>
          <h3>₹ {item?.attributes?.price}</h3>
        </div>
      </div>
    </Link>
  );
};

export default Card;
