import React from "react";
import style from "./Contact.module.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import PinterestIcon from "@mui/icons-material/Pinterest";

const Contact = () => {
  return (
    <div className={style.contact}>
      <div className={style.wrapper}>
        <span>BE IN TOUCH WITH US:</span>
        <div className={style.mail}>
          {/* <input type={style.text} placeholder="Enter your e-mail..." /> */}
          {/* <button>JOIN US</button> */}
          support@estore.com
        </div>
        <div className={style.icons}>
          <FacebookIcon />
          <InstagramIcon />
          <TwitterIcon />
          <GoogleIcon />
          <PinterestIcon />
        </div>
      </div>
    </div>
  );
};

export default Contact;
