import React, { useEffect } from "react";
import Card from "../Card/Card";
import style from "./List.module.scss";
import useFetch from "../../hooks/useFetch";
import Loader from "../Loader/Loader";

// const data = [
//   {
//     id: 1,
//     img: "https://images.pexels.com/photos/1188748/pexels-photo-1188748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     img2: "https://images.pexels.com/photos/769733/pexels-photo-769733.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     title: "Graphic Tshirt",
//     isNew: true,
//     oldPrice: 19,
//     price: 12,
//   },
//   {
//     id: 2,
//     img: "https://images.pexels.com/photos/1188748/pexels-photo-1188748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     img2: "",
//     title: "Graphic Tshirt",
//     isNew: true,
//     oldPrice: 19,
//     price: 12,
//   },
//   {
//     id: 3,
//     img: "https://images.pexels.com/photos/1188748/pexels-photo-1188748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     img2: "",
//     title: "Graphic Tshirt",
//     isNew: true,
//     oldPrice: 19,
//     price: 12,
//   },
//   {
//     id: 4,
//     img: "https://images.pexels.com/photos/1188748/pexels-photo-1188748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     img2: "https://images.pexels.com/photos/1188748/pexels-photo-1188748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     title: "Graphic Tshirt",
//     isNew: true,
//     oldPrice: 19,
//     price: 12,
//   },
// ];

interface ListData {
  catId: number | undefined;
  maxPrice: number;
  sort: string;
  subCats: [];
  setSelectedSubCats: React.Dispatch<React.SetStateAction<number[]>>;
}

const List = ({
  catId,
  maxPrice,
  sort,
  subCats,
  setSelectedSubCats,
}: ListData) => {
  // console.log(catId, subCats);

  // Reset sub-categories when catId changes

  useEffect(() => {
    if (catId !== undefined) {
      setSelectedSubCats([]); // Reset the subCats state when catId changes
    }
  }, [catId]);

  const subCatIds = subCats
    .map((item) => `[filters][sub_categories][id][$eq]=${item}`)
    .join("&");

  const { data, loading, error } = useFetch(
    `/products?populate=*&[filters][categories][id]=${catId}&${subCatIds}&[filters][price][$lte]=${maxPrice}&sort=price:${sort}`
  );

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data?.data) {
    <div className={style.list}>No products to display</div>;
  }

  return (
    <div className={style.list}>
      {!data?.data?.length && <div>No products to display</div>}

      {/* {catId}
      {subCats}
      {maxPrice}
      {sort} */}
      {loading
        ? "loading"
        : data?.data?.map((item: any) => <Card item={item} key={item.id} />)}
    </div>
  );
};

export default List;
