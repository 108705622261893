import React, { useEffect } from "react";
import style from "./Privacy.module.scss";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={style.privacy}>
      <h1>Privacy Policy</h1>
      <p>
        At E-STORE, we prioritize your privacy and security during your visit to
        our educational website. Please be aware that E-STORE is not an actual
        e-commerce store, and everything on this website, including the payment
        process, is purely in test mode for educational purposes only.
      </p>
      <ol>
        <li>
          <strong>Test Mode:</strong> E-STORE is an educational project and a
          virtual clothing website created for learning and experimentation.
          Please be aware that everything on this website, including the payment
          process, is purely in test mode and does not involve real transactions
          or deliveries. We do not collect any personal or payment information,
          and any data you enter during your visit is for demonstration and
          testing purposes only.
        </li>

        <li>
          <strong>Cookies:</strong> E-STORE may use cookies to enhance your
          browsing experience and for analytics purposes. These cookies are not
          used to identify you personally and are only used to improve the
          functionality of the website.
        </li>
        <li>
          <strong>External Links:</strong> Our website may contain links to
          external sites. Please be aware that we are not responsible for the
          content or privacy practices of these sites. We encourage you to
          review the privacy policies of any external websites you visit.
        </li>
        <li>
          <strong>Children's Privacy:</strong> E-STORE is not intended for
          children under the age of 13. We do not knowingly collect personal
          information from children. If you believe that we may have
          inadvertently collected information from a child, please contact us,
          and we will promptly remove the data.
        </li>
        <li>
          <strong>Changes to Policy:</strong> E-STORE's privacy policy is
          subject to change without prior notice. Any updates will be reflected
          on this page.
        </li>
      </ol>
      <p>
        If you have any questions or concerns about our privacy policy or the
        educational nature of E-STORE, please feel free to contact us. Thank you
        for being a part of our educational experience!
      </p>
    </div>
  );
};

export default Privacy;
