import style from "../Cart/Cart.module.scss";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useSelector, useDispatch } from "react-redux";
import { removeItem, resetCart } from "../../redux/cartReducer";
import { loadStripe } from "@stripe/stripe-js";
import { makeRequest } from "../../makeRequest";
import { useState } from "react";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";

const Cart = () => {
  // const data = [
  //   {
  //     id: 1,
  //     img: "https://images.pexels.com/photos/1188748/pexels-photo-1188748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //     img2: "https://images.pexels.com/photos/769733/pexels-photo-769733.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //     title: "Graphic Tshirt",
  //     isNew: true,
  //     oldPrice: 19,
  //     price: 12,
  //     desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo  Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo",
  //   },
  //   {
  //     id: 2,
  //     img: "https://images.pexels.com/photos/1188748/pexels-photo-1188748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  //     img2: "",
  //     title: "Graphic Tshirt",
  //     isNew: true,
  //     oldPrice: 19,
  //     price: 12,
  //     desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo",
  //   },
  // ];
  const products = useSelector((state: any) => state.cart.products);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const totalPrice = () => {
    let total = 0;
    products.forEach((item: any) => {
      total += item.quantity * item.price;
    });
    return total.toFixed(2);
  };

  const stripePromise = loadStripe(
    "pk_test_51NWYSESBcrqKeP9h5SdqXm0rFdqkTnLY1A1uNJQOek150smk5ltAzPYufg3IxPu3hojSlXL9cZS6rITwqGMhfjpt00HpdGOnla"
  );

  const handlePayment = async () => {
    try {
      const stripe: any = await stripePromise;

      setLoading(true);
      const res = await makeRequest.post("/orders", {
        products,
      });
      setLoading(false);

      await stripe.redirectToCheckout({
        sessionId: res.data.stripeSession.id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {loading && (
        <div>
          <Loader />
        </div>
      )}
      <div className={style.cart}>
        <h1>Product in your cart</h1>
        {products.map((item: any) => (
          <div className={style.item} key={item.id}>
            {/* <img src={process.env.REACT_APP_UPLOAD_URL + item.img} alt="" /> */}
            <img src={item.img} alt="" />

            <div className={style.details}>
              <h1 className={style.title}>{item.title}</h1>
              <p>{item.desc?.substring(0, 50)}...</p>
              <div className={style.price}>
                {" "}
                {item.quantity} x ₹{item.price}
              </div>
            </div>
            <DeleteOutlinedIcon
              className="delete"
              onClick={() => dispatch(removeItem(item.id))}
            />
          </div>
        ))}
        <div className={style.total}>
          <span>SUBTOTAL</span>
          <span>₹{totalPrice()}</span>
        </div>
        <button onClick={handlePayment}>PROCEED TO CHECKOUT</button>

        <span className={style.reset} onClick={() => dispatch(resetCart())}>
          Reset Cart
        </span>
      </div>
    </>
  );
};

export default Cart;
