import { useEffect, useState } from "react";
import style from "./Product.module.scss";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BalanceIcon from "@mui/icons-material/Recycling";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/cartReducer";
import Loader from "../../components/Loader/Loader";

const Product = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [selectedImg, setSelectedImg] = useState("img");
  const [quantity, setQuantity] = useState(1);
  const id = useParams().id;

  const dispatch = useDispatch();
  const { data, loading, error } = useFetch(`/products/${id}?populate=*`);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={style.product}>
      <div className={style.left}>
        <div className={style.images}>
          <img
            src={
              // process.env.REACT_APP_UPLOAD_URL +
              data?.data?.attributes?.img?.data.attributes.url
            }
            alt="img1"
            onClick={(e) => setSelectedImg("img")}
          />
          <img
            src={
              // process.env.REACT_APP_UPLOAD_URL +
              data?.data?.attributes?.img2?.data?.attributes?.url
            }
            alt="img2"
            onClick={(e) => setSelectedImg("img2")}
          />
          {/* <img src={images[1]} alt="" onClick={() => setSelectedImg(1)} /> */}
        </div>
        <div className={style.mainImg}>
          <img
            src={
              // process.env.REACT_APP_UPLOAD_URL +
              data?.data?.attributes[selectedImg]?.data?.attributes?.url
            }
            alt=""
          />
        </div>
      </div>
      <div className={style.right}>
        <h1>{data?.data?.attributes?.title}</h1>
        <span className={style.price}>₹ {data?.data?.attributes?.price}</span>
        <p>{data?.data?.attributes?.desc}</p>
        <div className={style.quantity}>
          <button
            onClick={() => setQuantity((prev) => (prev === 1 ? 1 : prev - 1))}
          >
            -
          </button>
          {quantity}
          <button onClick={() => setQuantity((prev) => prev + 1)}>+</button>
        </div>
        <button
          className={style.add}
          onClick={() =>
            dispatch(
              addToCart({
                id: data?.data?.id,
                title: data?.data?.attributes?.title,
                desc: data?.data?.attributes?.desc,
                price: data?.data?.attributes?.price,
                img: data?.data?.attributes?.img?.data.attributes.url,
                quantity,
              })
            )
          }
        >
          <AddShoppingCartIcon />
          ADD TO CART
        </button>
        <div className={style.links}>
          <div className={style.item}>
            <LocalShippingIcon />
            FREE SHIPPING
          </div>
          <div className={style.item}>
            <BalanceIcon />
            ECO-FRIENDLY PACKAGING
          </div>
        </div>
        <div className={style.info}>
          <span>Size: {data?.data?.attributes?.size}</span>
          <span>Seller: E-STORE</span>
          <span>Availability: In stock</span>
          {/* <span>Tag: T-Shirt, Women, Top</span> */}
        </div>
        <hr />
        {/* <div className={style.info}>
          <span>DESCRIPTION</span>
          <hr />
          <span>ADDITIONAL INFORMATION</span>
          <hr />
          <span>FAQ</span>
        </div> */}
      </div>
    </div>
  );
};

export default Product;
