import { Link } from "react-router-dom";
import style from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.top}>
        <div className={style.item}>
          <h1>Categories</h1>
          <span>
            <Link className="link" to="/products/4">
              Women
            </Link>
          </span>
          <span>
            <Link className="link" to="/products/3">
              Men
            </Link>
          </span>
        </div>
        <div className={style.item}>
          <h1>Links</h1>
          <span>
            <Link className="link" to="/">
              Home
            </Link>
          </span>
          <span>
            <Link className="link" to="/about">
              About
            </Link>
          </span>
          <span>
            <Link className="link" to="/privacy">
              Privacy
            </Link>
          </span>
        </div>
        <div className={style.item}>
          <h1>About</h1>
          <span>
            E-STORE is a virtual clothing website dedicated to providing an
            educational and engaging experience. Our mission is to showcase the
            latest in web technologies and e-commerce while offering a diverse
            collection of clothing for your virtual browsing pleasure.
          </span>
        </div>
        <div className={style.item}>
          <h1>Privacy</h1>
          <span>
            At E-STORE, we prioritize your privacy and security during your
            visit to our educational website. Please be aware that E-STORE is
            not an actual e-commerce store, and everything on this website,
            including the payment process, is purely in test mode for
            educational purposes only.
          </span>
        </div>
      </div>
      <div className={style.bottom}>
        <div className={style.left}>
          <span className={style.logo}>E-STORE</span>
          <span className={style.copyright}>
            © Copyright 2023. All Rights Reserved
          </span>
        </div>
        <div className={style.right}>
          <img src="/img/payment.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
