import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Link } from "react-router-dom";
import style from "./Navbar.module.scss";
import Cart from "../Cart/Cart";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const products = useSelector((state: any) => state.cart.products);
  const [isActive, setActive] = useState(false);

  const toggleActiveClass = (): void => {
    setActive(!isActive);
  };

  return (
    <div className={style.navbar}>
      <div className={`${style.hamburger} ${isActive ? style.active : ""}`}>
        <div onClick={toggleActiveClass}>
          <span className={`${style.bar}`}></span>
          <span className={`${style.bar}`}></span>
          <span className={`${style.bar}`}></span>
        </div>
        <div>
          <Link className={style.link} to="/">
            E-STORE
          </Link>
        </div>
        <div>
          <div className={style.icons}>
            <div className={style.cartIcon} onClick={() => setOpen(!open)}>
              <ShoppingCartOutlinedIcon />
              <span>{products.length}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${style.wrapper} ${isActive ? style.active : ""}`}
        onClick={toggleActiveClass}
      >
        <div className={style.left}>
          {/* <div className={style.item}> */}
          {/* <img src="img/en.png" alt="" /> */}
          {/* <KeyboardArrowDownIcon /> */}
          {/* </div> */}
          {/* <div className={style.item}>
            <span>INR</span> */}
          {/* <KeyboardArrowDownIcon /> */}
          {/* </div> */}
          <div className={style.item}>
            <Link className="link" to="/products/4">
              Women
            </Link>
          </div>
          <div className={style.item}>
            <Link className="link" to="/products/3">
              Men
            </Link>
          </div>
          {/* <div className={style.item}>
            <Link className="link" to="/products/3">
              Children
            </Link>
          </div> */}
        </div>
        <div className={style.center}>
          {" "}
          <Link className={style.link} to="/">
            E-STORE
          </Link>
        </div>
        <div className={style.right}>
          <div className={style.item}>
            <Link className={style.link} to="/">
              Homepage
            </Link>
          </div>
          <div className={style.item}>
            <Link className={style.link} to="/about">
              About
            </Link>
          </div>
          <div className={style.item}>
            <Link className={style.link} to="/privacy">
              Privacy
            </Link>
          </div>
          {/* <div className={style.item}>
            <Link className={style.link} to="/">
              Contact
            </Link>
          </div>
          <div className={style.item}>
            <Link className={style.link} to="/">
              Stores
            </Link>
          </div> */}
          <div className={style.icons}>
            {/* <SearchIcon /> */}
            {/* <PersonOutlineOutlinedIcon /> */}
            {/* <FavoriteBorderOutlinedIcon /> */}
            <div className={style.cartIcon} onClick={() => setOpen(!open)}>
              <ShoppingCartOutlinedIcon />
              <span>{products.length}</span>
            </div>
          </div>
        </div>
      </div>

      {open && <Cart />}
    </div>
  );
};

export default Navbar;
