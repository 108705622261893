import React, { useEffect } from "react";
import style from "./About.module.scss";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={style.about}>
      <h1>About Us</h1>
      <p>
        E-STORE is an <strong>educational project</strong> and a virtual
        clothing website created for learning and experimentation. Please be
        aware that E-STORE is not an actual e-commerce store, and everything on
        this website, including the payment process, is purely in test mode for
        educational purposes only.
      </p>

      <p>
        Join us on this simulated journey at E-STORE, and explore a diverse
        collection of clothing, ranging from casual outings to formal events and
        athletic activities.
      </p>

      <p>Thank you for being a part of our educational experience!</p>
      <h2>Frontend Tech:</h2>
      <ul>
        <li>React</li>
        <li>TypeScript</li>
        <li>SCSS</li>
        <li>Redux Toolkit</li>
        <li>Custom Fetch Hook</li>
        <li>No Component Library Used</li>
        <li>Built from scratch using Grid and Flex for layout</li>

        <li>Axios</li>
      </ul>
      <h2>Backend Tech:</h2>
      <ul>
        <li>Strapi</li>
      </ul>
      <h2>Hosting:</h2>
      <p>Frontend: Vercel</p>
      <p>Backend: AWS EC2</p>
      <p>
        Join us on this simulated journey at E-STORE, where we explore the
        fascinating blend of style and technology for educational purposes only.
      </p>
      <p>Thank you for being a part of our educational experience!</p>
    </div>
  );
};

export default About;
