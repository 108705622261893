import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export interface CounterState {
  products: any[];
}

const initialState: CounterState = {
  products: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = state.products.find((item) => {
        // console.log("aaddToCart", item.id, action.payload.id);
        return item.id === action.payload.id;
      });
      if (item) {
        item.quantity += action.payload.quantity;
        toast.success("Quantity updated in cart!", {
          position: "top-center",
          autoClose: 3000, // Auto-close the message after 3000ms (3 seconds)
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        state.products.push(action.payload); // This line should be removed
        toast.success("Item added to cart!", {
          position: "top-center",
          autoClose: 3000, // Auto-close the message after 3000ms (3 seconds)
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    },
    removeItem: (state, action) => {
      state.products = state.products.filter(
        (item) => item.id !== action.payload
      );
    },

    resetCart: (state) => {
      state.products = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToCart, removeItem, resetCart } = cartSlice.actions;

export default cartSlice.reducer;
